/*
 * File: Unsuscribe.tsx
 * Project: autobuyclient
 * File Created: Wednesday, 17th January 2024 03:14:00
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 17th January 2024 06:16:03
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

.global {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.spinnerHolder {
    position: absolute;
    inset:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.5);
}

.formHolder {
    position: absolute;
    display: flex;
    width: 80%;
    height: 60%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    padding: 2rem;
}
