/*
 * File: ValidationSuccess.module.css
 * Project: autobuyclient
 * File Created: Monday, 18th September 2023 10:57:38
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 19th September 2023 11:27:16
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

 .createAccountPage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.spinnerHolder {
    position: absolute;
    inset:0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.5);
}

.formHolder {
    position: absolute;
    display: flex;
    width: 80%;
    height: 60%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    padding: 2rem;
}
